body {
  font-family: "Lato", sans-serif;
}
@import "~react-image-gallery/styles/css/image-gallery.css";
h4 {
  color: black;
}
p {
  color: black;
  font-size: 20px;
}
h1,
h2,
ul {
  color: #1e5a70;
  font-weight: bold;
}
a {
  text-decoration: none;
  color: black;
}

/* HEADER */

.headerTop {
  display: flex;
  flex-direction: row;
  background-color: #8abdc6;
  padding-block: 20px;
}

.headerTop span,
.headerTop img,
.headerBottom img,
.headerBottom h4,
.homeContainer {
  display: inline-block;
  opacity: 0;
  transform: translateY(10px);
  animation: fadeInUp 2s ease;
  animation-fill-mode: both;
}
.correoLink{
  color: #1e5a70;
  font-weight: bold;
}

.correoLink:hover{
  color: #1e5a70;
  font-weight: bold;
  text-decoration: underline;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.headerBottom {
  display: flex;
  flex-direction: row;
  background-color: #64a4af;
  padding-block: 15px;
  align-items: center;
  justify-content: space-around;
}
.headerImg {
  height: 40px;
  padding-left: 20px;
  padding-right: 10px;
}
.headerLogo {
  height: 100px;
}

.hover-border {
  position: relative;
  cursor: pointer;
  display: inline-block;
  transition: color 0.3s;
  padding: 5px;
}

.hover-border::before,
.hover-border::after {
  content: "";
  position: absolute;
  background-color: #8abdc6;
  transition: transform 0.3s;
}

.hover-border::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transform-origin: left;
}

.hover-border::after {
  bottom: 0;
  right: 0;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transform-origin: right;
}

.hover-border:hover::before {
  transform: scaleX(1);
}

.hover-border:hover::after {
  transform: scaleX(1);
}

/* HOME */
.homeContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.carouselContainer {
  width: 60%;
}
.carouselBottom {
  background-color: #64a4af;
  padding: 10px;
  margin-left: -30%;
  width: 30%;
}
.carouselImg {
  width: 100%;
  height: 700px;
}
.homeDatos {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* Subir */
  }
}

.letter-animation span {
  display: inline-block;
  margin-top: 5px;
  animation: bounce 1s infinite alternate;
  cursor: pointer;
}

/* Aplicar retraso a cada letra */
.letter-animation span:nth-child(1) {
  animation-delay: 0.1s;
}

.letter-animation span:nth-child(2) {
  animation-delay: 0.2s;
}

.letter-animation span:nth-child(3) {
  animation-delay: 0.3s;
}

.letter-animation span:nth-child(4) {
  animation-delay: 0.4s;
}

.letter-animation span:nth-child(5) {
  animation-delay: 0.5s;
}

.letter-animation span:nth-child(6) {
  animation-delay: 0.6s;
}

.letter-animation span:nth-child(7) {
  animation-delay: 0.7s;
}

.letter-animation span:nth-child(8) {
  animation-delay: 0.8s;
}

.letter-animation span:nth-child(9) {
  animation-delay: 0.9s;
}

/* SERVICIOS QUE OFRECEMOS */
.contactoEmailContainer{
  font-size: 20px;
}

.titulo{
  font-size: 60px;
  margin-block: 40px;
}

.spanEmail{
  font-weight: bold;
}

.containerObrasServicios {
  margin-top: 75px;
  margin-inline: 200px;
}
.servicioImg {
  width: 300px;
  height: 300px;
}

.containerCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.customCard {
  width: 20rem; /* Adjust the width as needed */
  margin: 0 1rem;
  margin-bottom: 20px;
}

.cardImage {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.card {
  margin-bottom: 20px;
  transition: transform 0.3s;
}

.card:hover {
  transform: scale(1.05);
}

.containerSoluciones {
  margin-top: 75px;
}
.listaSoluciones {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.textoSoluciones {
  flex: 1;
}

.formItemsContainer {
  display: flex;
  flex-direction: column;
}
.firstItemForm {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.fromImput {
  width: 400px;
  height: 40px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #1e5a70;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #bbd7db;
}
.input-container {
  display: flex;
  flex-direction: column;
}

.form-input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #1e5a70;
  border-radius: 4px;
  transition: height 0.3s;
  overflow: hidden;
  resize: vertical;
  background-color: #bbd7db;
  white-space: pre-wrap;
}
.btn-color {
  background-color: #1e5a70;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid black;
}
.btn-color:hover {
  background-color: #1e5a70;
}

/* OBRA */

.obraContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -3rem;
}

.obraContainer > * {
  width: calc(33.33% - 2rem);
  margin: 0 1rem;
  margin-bottom: 1.5rem;
}
.carouselImgObra {
  width: 100%;
  height: 350px;
}
.form-container {
  position: relative;
}

.inoperable-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
/* FOOTER */

.footer {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 350px;
  background-color: #64a4af;
}
.footerImg {
  width: 40px;
  margin-right: 10px;
  margin-top: 10px;
}
.footerItemContainer {
  display: flex;
  align-items: center;
}
.footerItemContainerGlobal {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.socialLinks div a {
  transition: all 0.3s ease;
  text-decoration: none;
  color: black;
}

.socialLinks div:hover a {
  color: #1e5a70;
  text-shadow: 0 0 10px #3417fb;
}
.bigLogoContainer {
  display: flex;
  justify-content: center;
}
.bigLogo {
  max-width: 80%;
  height: auto;
  cursor: pointer;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 900px) {
  .titulo{
    display: none;
  }
  .headerTop {
    flex-direction: column;
    text-align: center;
  }
  .headerBottom {
    flex-direction: column;
  }
  .carouselContainer {
    width: 90%;
  }
  .carouselImg {
    height: 300px;
  }
  .carouselBottom {
    background-color: #64a4af;
    padding: 10px;
    margin-left: -35%;
    width: 55%;
  }
  .containerObrasServicios {
    margin-top: 50px;
    margin-inline: 50px;
  }
  .containerCards {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 75px;
    align-content: center;
  }
  .card {
    margin-bottom: 50px;
  }
  .listaSoluciones {
    flex-direction: column;
  }
  .footer {
    height: 950px;
    flex-direction: column;
  }
  .fromImput {
    width: 250px;
    height: 40px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #1e5a70;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #bbd7db;
  }
}
